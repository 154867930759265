import axios from 'axios'

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL
const ACCESS_TOKEN_KEY = 'access_token'

export function readEvents () {
  const token = sessionStorage.getItem(ACCESS_TOKEN_KEY)
  axios.defaults.headers.common['Authorization'] = 'Token ' + token
  const url = `${BACKEND_URL}/api/events/`
  return axios.get(url)
  .then(response => response.data)
}

export function createEvent (newEvent) {
  const url = `${BACKEND_URL}/api/events/`
  return axios.post(url, newEvent)
}

export function updateEvent (thisEvent) {
  // TODO: Skal bare kunne endre info i event, ellers skal det være endring av datoer
  const url = `${BACKEND_URL}/api/events/${thisEvent.id}/`
    // TODO: sjekk om jeg skal bruke PUT eller POST eller PATCH
  return axios.put(url, thisEvent)
}

export function deleteEvent (thisEvent) {
  const url = `${BACKEND_URL}/api/events/${thisEvent.id}`
  return axios.delete(url)
}


export function readBlog () {
  const token = sessionStorage.getItem(ACCESS_TOKEN_KEY)
  axios.defaults.headers.common['Authorization'] = 'Token ' + token
  const url = `${BACKEND_URL}/api/info`
    return axios.get(url)
}
