import Vue from 'vue'
import Router from 'vue-router'
import Info from './views/Info.vue'
import About from './views/About.vue'
import Tidspunkt from './views/Tidspunkt.vue'
import LoginComponent from './components/login.vue'
import LogoutComponent from './components/logout.vue'
import { loggedIn } from '../utils/auth'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!loggedIn()) {
    next()
    return
  }
  next('/home')
}

const ifAuthenticated = (to, from, next) => {
  if (loggedIn()) {
    next()
    return
  }
  next('/login')
}


export default new Router({
  routes: [
    {
      path: '/about',
      name: 'about',
      component: About,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/',
      name: 'info',
      component: Info,
      // meta: { requiresAuth : true },
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tidspunkt',
      name: 'tidspunkt',
      component: Tidspunkt,
      // meta: { requiresAuth : true }
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginComponent,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutComponent
    },
  ]
})

