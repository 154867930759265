// import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: colors.cyan.darken3,
                secondary: colors.red.lighten4, // #FFCDD2
                accent: colors.indigo.base // #3F51B5
            }
        }
    }
});
