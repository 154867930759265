<template>
    <v-layout row justify-center>
        <v-card class="ma-2">
            <v-toolbar dark color="primary">
                <v-toolbar-title>Logg på</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-form ref="form">
                    <v-text-field
                        type="text"
                        name="username"
                        v-model="input.username"
                        placeholder="Brukernavn"
                    />
                    <v-text-field
                        type="password"
                        name="password"
                        v-model="input.password"
                        placeholder="Passord"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                    <v-btn @click="handleLogin()" color="primary">Login</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</template>

<script>
import { login } from "../../utils/auth";
export default {
    name: "Login",
    data() {
        return {
            input: {
                username: "",
                password: ""
            }
        };
    },
    methods: {
        handleLogin() {
            login(this.input.username, this.input.password).then(() => {
                this.$router.push({ name: "info" });
            });
            // this.$router.push({ name: "tidspunkt" });
            // if(this.input.username == this.$parent.mockAccount.username && this.input.password == this.$parent.mockAccount.password) {
            //   this.$emit("authenticated", true);
            //   this.$router.replace({ name: "secure" });
            // } else {
            //   console.log("The username and / or password is incorrect");
            // }
            // this.$router.push({ name: "info" });
        }
    },
    updated() {},
    mounted() {
        // this.$router.push({ path: "/" })
    }
};
</script>

<style scoped>
</style>
