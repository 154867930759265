<template>
  <v-layout justify-center>
    <h2>
    Du er nå logget ut
    
    </h2>
  </v-layout>
</template>
<script>
import { logout } from '../../utils/auth'
export default {
  created () {
    logout()
  },
}
</script>
