import axios from 'axios'

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL
const ACCESS_TOKEN_KEY = 'access_token'

export function login(username, password) {
  const url = `${BACKEND_URL}/api-token-auth/`
  var result = axios.post(url, { username: username, password:password })
  result
  .then(function (response) {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, response.data.token)
    axios.defaults.headers.common['Authorization'] = 'Token ' + response.data.token
  })
  .catch(function (error) {
    // console.log(error)  // FIXME: Only for debugging
  })
  return result
}

export function logout() {
  clearAccessToken()
  axios.defaults.headers.common['Authorization'] = ''
}

export function getAccessToken() {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY)
}

export function loggedIn() {
  if (getAccessToken() == null) {
    return false
  } else {
    return true
  }
}

function clearAccessToken() {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
}

