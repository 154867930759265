<template>
    <div id="e3" style="max-width: 600px; margin: auto;">
        <div v-for="info in infos" v-bind:key="info.id" class="pb-4">
            <v-card :color="info.color" class="white--text">
                <v-card-title primary-title>
                    {{ info.title }}
                </v-card-title>
                <v-card-text class="white--text">
                    {{ info.text }}
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            infos: [
                {
                    title: "Hva er Samlingstider?",
                    text:
                        'Samlingstider er et nettsted for Den læstadianske forsamling i Norge. Her kan annonseansvarlige i hver forsamling legge inn annonser for flere uker eller måneder fram i tid. Det er bare annonseansvarlige som får tilgang til disse sidene. Trykk "Annonser" i menyen for å legge til, endre eller slette annonser.',
                    color: "cyan darken-2"
                },
                {
                    title: "Forsamlingas hjemmesider",
                    text:
                        'Annonsense du legger inn her på Samlingstider blir automatisk synlige på forsamlingas hjemmeside. Hjemmesidene viser annonser tre måneder fram i tid.  Gamle annonser blir automatisk borte fra hjemmesidene selv om du ikke har fjernet annonsen fra Samlingstider. Du kan trykke "Åpne hjemmeside" i menyen for å sjekke forsamlingas hjemmeside og annonsene du har lagt inn.',
                    color: "grey darken-3"
                },
                {
                    title: "Husk",
                    text:
                        "Husk at du regelmessig sjekker Informasjons-siden (trykk 'Informasjon' i menyen).  Her kan det komme viktig informasjon fra systemadministrator.",
                    color: "purple"
                }
            ]
        };
    }
};
</script>
