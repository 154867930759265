<template>
    <div>
        <v-toolbar color="white">
            <!-- Deactivated switch
            <v-row>
                <v-switch
                    v-model="switch1"
                    :label="`Visningsmodus: tabell/kort`"
                ></v-switch>
                <v-spacer></v-spacer>
            </v-row>
            -->

            <v-dialog v-model="newDialog" max-width="1000px">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        rounded
                        slot="activator"
                        color="primary"
                        dark
                        >Legg til ny annonse
                    </v-btn>
                </template>

                <v-card>
                    <v-card color="primary" class="white--text">
                        <v-card-title>
                            Ny aktivitet
                        </v-card-title>
                    </v-card>

                    <v-card-text>
                        <v-container fluid>
                            <span>
                                1) Velg type aktivitet. 2) Velg klokkeslett. 3)
                                Hvis du vil kan du skrive inn utfyllende tekst.
                                4) Velg en eller flere datoer. 5) Trykk 'Lagre'.
                            </span>
                        </v-container>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        :items="eventAlternatives"
                                        v-model="Item.name"
                                        label="Aktivitet"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-time-picker
                                        v-model="Item.starttime"
                                        format="24hr"
                                        :landscape="$vuetify.breakpoint.mdAndUp"
                                        :allowed-minutes="allowedStep"
                                    >
                                    </v-time-picker>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        solo
                                        v-model="Item.info"
                                        name="input-7-4"
                                        label="Annonsetekst"
                                        value=""
                                    ></v-textarea>
                                </v-col>

                                <v-col cols="12">
                                    <v-date-picker
                                        v-model="dates"
                                        multiple
                                        first-day-of-week="1"
                                        locale="nb"
                                        :landscape="$vuetify.breakpoint.mdAndUp"
                                    ></v-date-picker>
                                </v-col>

                                <v-col cols="12">
                                    <v-menu
                                        ref="menu"
                                        :close-on-content-click="false"
                                        v-model="menu"
                                        :nudge-right="40"
                                        :return-value.sync="dates"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <v-combobox
                                            slot="activator"
                                            v-model="dates"
                                            multiple
                                            chips
                                            small-chips
                                            prepend-icon="event"
                                            readonly
                                        ></v-combobox>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close"
                            >Avbryt</v-btn
                        >
                        <v-btn color="primary" text @click="save">Lagre</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="editDialog" max-width="1000px">
                <v-card>
                    <v-col>
                        <v-card color="primary" class="white--text">
                            <v-card-title>
                                Redigere aktivitet
                            </v-card-title>
                        </v-card>
                    </v-col>

                    <v-card-title>
                        {{ Item.name }} kl
                        {{ stripSeconds(Item.starttime) }}
                    </v-card-title>

                    <v-card-text>
                        Her kan du endre på annonsetekst og datoer for denne
                        aktiviteten.
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-col cols="12">
                                    <v-textarea
                                        solo
                                        v-model="Item.info"
                                        name="input-7-4"
                                        label="Annonsetekst"
                                        value=""
                                    ></v-textarea>
                                </v-col>

                                <v-col cols="12">
                                    <v-date-picker
                                        v-model="dates"
                                        multiple
                                        first-day-of-week="1"
                                        locale="nb"
                                        :landscape="$vuetify.breakpoint.mdAndUp"
                                    ></v-date-picker>
                                </v-col>

                                <v-col cols="12">
                                    <v-menu
                                        ref="menu"
                                        :close-on-content-click="false"
                                        v-model="menu"
                                        :nudge-right="40"
                                        :return-value.sync="dates"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <v-combobox
                                            slot="activator"
                                            v-model="dates"
                                            multiple
                                            chips
                                            small-chips
                                            prepend-icon="event"
                                            readonly
                                        ></v-combobox>
                                    </v-menu>
                                </v-col>
                            </v-layout>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close"
                            >Avbryt</v-btn
                        >
                        <v-btn color="primary" text @click="update"
                            >Lagre</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>

        <div v-if="switch1" class="mt-6 mx-2">
            <v-card color="primary" class="white--tex pa-2">
                <v-row class="">
                    <v-col v-for="(event, index) in events" :key="index" cols="12" md="6" lg="4">
                        <v-card class="">
                            <v-card-title primary-title>
                                {{ event.name }} kl
                                {{ stripSeconds(event.starttime) }}
                            </v-card-title>
                            <v-card-text>
                                <span>{{ event.info }}</span>
                                <v-combobox
                                    slot="activator"
                                    v-model="event.dates"
                                    multiple
                                    chips
                                    small-chips
                                    prepend-icon="event"
                                    readonly
                                ></v-combobox>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="editItem(event)">Endre</v-btn>
                                <v-btn @click="deleteItem(event)">Slett</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <v-data-table
            :headers="headers"
            :items="events"
            class="elevation-1"
            hide-default-footer
            v-if="!switch1"
        >
            <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.starttime }}</td>
                <td class="text-xs-left">{{ props.item.info }}</td>
                <td class="text-xs-left">
                    <v-combobox
                        slot="activator"
                        v-model="props.item.dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="event"
                        readonly
                    ></v-combobox>
                </td>
                <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">
                        edit
                    </v-icon>
                    <v-icon small @click="deleteItem(props.item)">
                        delete
                    </v-icon>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {
    readEvents,
    createEvent,
    updateEvent,
    deleteEvent
} from "../../utils/backend-api.js";
export default {
    data: function() {
        return {
            switch1: true,
            newDialog: false,
            editDialog: false,
            menu: false,
            headers: [
                { text: "Aktivitet", value: "name" },
                { text: "Tidspunkt", value: "starttime" },
                { text: "Info", value: "info" },
                { text: "Datoer", value: "dates" },
                { text: "Endre/Slett", value: "id" }
            ],
            events: [],
            editedIndex: -1,
            Item: {
                name: "Gudstjeneste",
                starttime: "11:00:00"
            },
            dates: [],
            eventAlternatives: [
                { text: "Gudstjeneste" },
                { text: "Konfirmasjonsgudstjeneste" },
                { text: "Vielse" },
                { text: "Begravelse" },
                { text: "Barne- og ungdomsarbeid" },
            ]
        };
    },

    methods: {
        allowedStep: m => m % 15 === 0,

        stripSeconds(time) {
            return time.substring(0, 5);
        },

        clearItem() {
            this.Item = {
                name: "Gudstjeneste",
                starttime: "11:00:00"
            };
            this.dates = [];
        },

        async getEvents() {
            try {
                const response = await readEvents();
                this.events = response;
                // console.log(this.events);
            } catch (error) {
                // console.log(error);
            }
        },

        close() {
            this.clearItem();
            this.newDialog = false;
            this.editDialog = false;
        },

        async save() {
            try {
                await createEvent({
                    name: this.Item.name,
                    starttime: this.Item.starttime,
                    info: this.Item.info,
                    dates: this.dates
                });
            } catch (error) {
                // console.log(error);
            }
            this.getEvents();
            this.close();
        },

        async update() {
            try {
                await updateEvent({
                    id: this.Item.id,
                    name: this.Item.name,
                    starttime: this.Item.starttime,
                    info: this.Item.info,
                    dates: this.dates
                });
            } catch (error) {
                // console.log(error);
            }
            this.getEvents();
            this.close();
        },

        async deleteItem(item) {
            if (confirm("Er du sikker på at du vil slette?")) {
                try {
                    await deleteEvent(item);
                    this.getEvents();
                } catch (error) {
                    // console.log(error);
                }
            }
        },

        editItem(item) {
            this.Item = Object.assign({}, item);
            this.dates = item.dates;
            this.editDialog = true;
        }
    },

    created() {
        this.getEvents();
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Ny hendelse" : "Rediger dato";
        }
    },
    watch: {
        // Vet ikke hensikten med dette (se Dok under vuetifyjs.com --> data-tables)
        dialog(val) {
            val || this.close();
        }
    }
};
</script>
