<template>
    <v-app>
        <v-app-bar app>
            <v-app-bar-nav-icon @click="drawer = !drawer">
                <v-icon large color="primary">
                    menu
                </v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="headline text-uppercase">
                <span>Samlings</span>
                <span class="font-weight-light">tider</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row>
                <v-spacer></v-spacer>

                <v-tab to="/logout" v-if="isLoggedIn">Logg ut</v-tab>
                <v-tab to="/login" v-else>Login</v-tab>

            </v-row>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list>
                <v-list-item-group>
                    <v-list-item to="/">
                        <v-list-item-icon>
                            <v-icon color="primary">
                                info
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            Informasjon
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/about">
                        <v-list-item-icon>
                            <v-icon color="primary">
                                article
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            Instruksjoner
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/tidspunkt">
                        <v-list-item-icon>
                            <v-icon color="primary">
                                calendar_today
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            Annonser
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
                <v-btn
                    text
                    href="https://www.samlingstider.no/accounts/password/reset/"
                    target="_self"
                >
                    <span class="mr-2">Nytt passord</span>
                </v-btn>

                <v-btn
                    text
                    href="https://www.laestadianerne.no"
                    target="_blank"
                >
                    <span class="mr-2">Åpne hjemmeside</span>
                </v-btn>
        </v-navigation-drawer>

        <v-main class="mt-4">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import { loggedIn } from "../utils/auth.js";

export default {
    name: "App",
    data: function() {
        return {
            //
            isLoggedIn: false,
            drawer: false
        };
    },
    methods: {
        checkLoggedIn() {
            this.isLoggedIn = loggedIn();
        }
    },
    updated() {
        this.checkLoggedIn();
    }
};
</script>
