import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import { loggedIn } from '../utils/auth'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

/*
router.beforeEach((to, from, next) => {
  console.log('to.path: ', to.fullPath)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('page requires auth')
    if (!loggedIn()) {
      next({
        path: '/login'
        // query: { redirect: to.fullPath }
      }) 
    } else {
      next ()
    }

  } else {
    next ()
  }
})
*/
