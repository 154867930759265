<template>
  <div class="tidspunkt">
    <div class="text-xs-center">
      <tidspunktListe></tidspunktliste>
    </div>
  </div>
</template>

<script>
  import tidspunktListe from '../components/tidspunktListe.vue'
  
  export default {
    data () {
      return {
      }
    },
  
    components: {
      tidspunktListe,
    },
  
  }
</script>
