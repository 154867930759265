<template>
    <div id="e3" style="max-width: 600px; margin: auto;" class="grey lighten-3">
        <v-card color="primary" class="white--text">
            <v-container fluid grid-list-lg>
                <h1>Informasjon</h1>
            </v-container>
        </v-card>

        <div v-for="blog in blogs" v-bind:key="blog.id">
            <v-card>
                <v-card-title primary-title>
                    {{ blog.overskrift }}
                </v-card-title>
                <v-card-text>
                    {{ blog.tekst }}
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import { readBlog } from "../../utils/backend-api";

export default {
    data() {
        return {
            blogs: []
        };
    },
    methods: {
        async getBlog() {
            try {
                const response = await readBlog();
                this.blogs = response.data;
            } catch (error) {
                // console.log(error);
            }
        }
    },
    created() {
        this.getBlog();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
